import * as React from 'react'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import { GatsbyImage } from 'gatsby-plugin-image'
import useCDNImage from '../hooks/useCDNImage'
import { gridSizes } from '../common/gatsbyImageData'
import PropTypes from 'prop-types'
import Section from '../components/cms/Section'
import IconLoaders from '../icons/IconLoaders'
import { NewArrivalsProductSliderCard } from '../components/sliders/NewArrivalSlider'
import DotSlider from '../components/sliders/DotSlider'
import { Link } from 'gatsby'
import { ProductSliderItemsPerScreen } from '../components/sliders/ProductSlider'
import { TAG_SALE, TAG_SELLABLE } from '../components/products/Tags'
import { IconMensWatch } from '../components/products/IconsProductPreloader'
import { IconChevronRight } from '../components/Icons'
import FavoriteItems from '../components/popups/FavoriteItem'
import CtaLink from '../components/cms/CtaLink'
import { CombineSections } from '../components/cms/SectionLayout'

const SeeAllSliderCardButton = ({ className = '', url }) => {
  return (
    <Link
      to={url || '/what-s-new/'}
      className={
        className +
        ' my-4 group relative  flex flex-col shrink-0 grow-0 items-center justify-center gap-2 rounded-md border-2 p-2 mx-2 shadow-md duration-300 ease-out lg:hover:scale-[102%] max-w-[250px] bg-red-700 text-white'
      }
    >
      <div className="grid grid-cols-1 grid-rows-1 p-3">
        <IconMensWatch flashing={false} className="w-full aspect-square stroke-1 stroke-white" />
      </div>
      <p className="text-xs xxs:text-base md:text-xl font-black uppercase">
        Shop
        <br />
        All
        <IconChevronRight className="stroke-2 stroke-white p-[.1rem] translate-y-[.1rem] ml-1" />
      </p>
    </Link>
  )
}
SeeAllSliderCardButton.propTypes = {
  className: PropTypes.string,
  url: PropTypes.string,
}

const CmsLandingPageSectionRenderer = ({ data }) => {
  let page = data.page
  const finalImage = useCDNImage(page.heroImage, { productType: 'Mens Watch' }, gridSizes, '50vw')
  const [perScreenFactory, linkClassName] = ProductSliderItemsPerScreen()
  const dotSliderSections = perScreenFactory()
  const pageSections = CombineSections(page.sections || [])
  const [fav, openFavorite] = React.useState(null)

  const linkedCategories = (data.linkedCategories?.nodes || []).filter(
    category =>
      category.productsInStock > 0 &&
      !category.name.startsWith('Other ') &&
      !category.name.startsWith('View All ')
  )
  // sort the linked categories by the number of products in stock
  linkedCategories.sort((a, b) => b.productsInStock - a.productsInStock)
  // limit to 9 categories
  linkedCategories.splice(9)

  return (
    <Layout breadcrumbs={true} canonical={page.url} title={page.title} className={'mx-auto w-full'}>
      <Seo seo={page.seo} title={page.title} canonical={page.url} article={page} />

      <div className="w-full flex flex-col md:flex-row max-w-[1366px] mx-auto ">
        <div
          className={
            'md:w-1/2 flex flex-col justify-center px-10 gap-2' +
            (page.layout === 'Image Left' ? ' order-2' : '')
          }
        >
          <h1 className="text-3xl lg:text-4xl font-bold ">{page.title}</h1>
          {page.subtitle && (
            <h2 className="text-xl lg:text-2xl font-bold italic">{page.subtitle}</h2>
          )}
          <p
            className="max-w-[800px] leading-loose mx-auto lg:text-lg"
            dangerouslySetInnerHTML={{ __html: page.content }}
          ></p>
          {page.CTA && <CtaLink {...page.CTA} />}
        </div>
        <div className="md:w-1/2 h-[30rem] flex justify-center items-center p-4">
          {finalImage ? (
            <GatsbyImage
              image={finalImage?.gatsbyImageData}
              alt={page.title}
              loading="eager"
              className="flex items-center justify-center w-full h-full"
              imgStyle={{ objectFit: 'contain' }}
            />
          ) : (
            <IconLoaders
              type={'Mens Watch'}
              flashing={false}
              className="flex items-center justify-center w-full h-full"
            />
          )}
        </div>
      </div>

      <hr className="w-full bg-gray-300" />
      {data.category && data.linkedCategories && data.linkedCategories && (
        <section className="w-full bg-gray-800">
          <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 w-10/12 sm:w-11/12 2xl:w-full max-w-[1366px] mx-auto py-10 gap-3 lg:gap-8 text-white">
            <h2 className="col-span-1 sm:col-span-2 xl:col-span-3 text-2xl font-bold uppercase">
              Popular {data.category?.tag?.brand || data.category?.name || data.page.title} Models
            </h2>
            {linkedCategories.map(category => {
              let tag = category.tag && category.tag[0] ? category.tag[0] : null
              if (!tag) {
                tag = {
                  brand: '!!!' + data.category.tag?.brand || data.category.name,
                  model: '!!!' + category.name,
                }
              }
              return (
                <Link key={category.urlPath} to={category.urlPath} className="text-white group">
                  {tag.brand}
                  <span className="text-xl uppercase block group-hover:translate-x-2 duration-300 ease-in-out">
                    {tag.model}
                  </span>
                </Link>
              )
            })}
            <Link
              to={data.category.urlPath}
              className="col-span-1 sm:col-span-2 xl:col-span-3 bg-red-600 text-white font-bold max-w-max px-5 py-3 rounded-full uppercase my-2 mx-auto hover:-translate-y-1 duration-300 ease-in-out"
            >
              Shop All {data.category.headerH1 || data.category.name}
            </Link>
          </div>
        </section>
      )}

      {data.category && data.linkedCategories && data.linkedCategories && (
        <section className={'w-11/12 mx-auto py-5'}>
          <h2>
            <Link className="underline-title" to={data.category.urlPath}>
              {page.title}
            </Link>
          </h2>
          <DotSlider dots={Math.ceil(data.items.nodes.length / dotSliderSections)}>
            {data.items.nodes.map(product => (
              <NewArrivalsProductSliderCard
                key={product.sku}
                className={linkClassName}
                showShopButton={false}
                tags={[TAG_SALE, TAG_SELLABLE]}
                product={product}
                openFavorite={openFavorite}
              />
            ))}
            <SeeAllSliderCardButton className={linkClassName} url={data.category.urlPath} />
          </DotSlider>
        </section>
      )}

      {data.category && data.linkedCategories && data.linkedCategories && (
        <hr className="w-full bg-gray-300" />
      )}

      {pageSections &&
        pageSections.map(section => {
          return (
            <Section
              key={section.id}
              section={section}
              className={'w-10/12 max-w-[1366px] mx-auto relative'}
            />
          )
        })}
      {fav && (
        <FavoriteItems
          className="absolute top-2 right-2 z-10"
          product={fav}
          setFavorite={openFavorite}
        />
      )}
    </Layout>
  )
}

CmsLandingPageSectionRenderer.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      title: PropTypes.string,
      subtitle: PropTypes.string,
      layout: PropTypes.string,
      content: PropTypes.string,
      service: PropTypes.string,
      category: PropTypes.string,
      publishedAt: PropTypes.any,
      updatedAt: PropTypes.any,
      url: PropTypes.string,
      seo: Seo.propTypes.seo,
      heroImage: PropTypes.shape({
        gatsbyImageDataMock: PropTypes.shape({
          width: PropTypes.number,
          height: PropTypes.number,
          url: PropTypes.string,
          ext: PropTypes.string,
        }),
      }),
      CTA: PropTypes.shape({
        title: PropTypes.string,
        url: PropTypes.string,
        openInNewTab: PropTypes.bool,
        download: PropTypes.bool,
      }),
      sections: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          heading: PropTypes.string,
          images: PropTypes.arrayOf(PropTypes.object),
          content: PropTypes.string,

          videos: PropTypes.arrayOf(
            PropTypes.shape({
              title: PropTypes.string,
              url: PropTypes.string,
            })
          ),
          strapi_component: PropTypes.string,
        })
      ),
    }),
    category: PropTypes.shape({
      productsInStock: PropTypes.number,
      urlPath: PropTypes.string,
      headerH1: PropTypes.string,
      name: PropTypes.string,
      tag: PropTypes.shape({ model: PropTypes.string, brand: PropTypes.string }),
    }),
    linkedCategories: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          productsInStock: PropTypes.number,
          urlPath: PropTypes.string,
          headerH1: PropTypes.string,
          name: PropTypes.string,
          tag: PropTypes.shape({ model: PropTypes.string, brand: PropTypes.string }),
        })
      ),
    }),
    items: PropTypes.arrayOf(PropTypes.object),
  }),
}

export default CmsLandingPageSectionRenderer
