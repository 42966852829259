import * as React from 'react'
import Seo from '../components/Seo'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'

import CmsLandingPageSectionRenderer from '../section-renderer/landing-page'

const CmsLandingPage = ({ data }) => {
  return <CmsLandingPageSectionRenderer data={data} />
}

// proptypes for the page
CmsLandingPage.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      title: PropTypes.string,
      subtitle: PropTypes.string,
      content: PropTypes.string,
      service: PropTypes.string,
      category: PropTypes.string,
      publishedAt: PropTypes.any,
      updatedAt: PropTypes.any,
      url: PropTypes.string,
      seo: Seo.propTypes.seo,
      CTA: PropTypes.shape({
        title: PropTypes.string,
        url: PropTypes.string,
        openInNewTab: PropTypes.bool,
        download: PropTypes.bool,
      }),
      heroImage: PropTypes.shape({
        gatsbyImageDataMock: PropTypes.shape({
          width: PropTypes.number,
          height: PropTypes.number,
          url: PropTypes.string,
          ext: PropTypes.string,
        }),
      }),
      sections: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          heading: PropTypes.string,
          images: PropTypes.string,
          content: PropTypes.string,
          videos: PropTypes.arrayOf(
            PropTypes.shape({
              title: PropTypes.string,
              url: PropTypes.string,
            })
          ),
          strapi_component: PropTypes.string,
        })
      ),
    }),
    category: PropTypes.shape({
      productsInStock: PropTypes.number,
      urlPath: PropTypes.string,
      name: PropTypes.string,
      headerH1: PropTypes.string,
      tag: PropTypes.shape({ model: PropTypes.string, brand: PropTypes.string }),
    }),
    linkedCategories: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          productsInStock: PropTypes.number,
          urlPath: PropTypes.string,
          name: PropTypes.string,
          headerH1: PropTypes.string,
          tag: PropTypes.shape({ model: PropTypes.string, brand: PropTypes.string }),
        })
      ),
    }),
    items: PropTypes.arrayOf(PropTypes.object),
  }),
}

export const query = graphql`
  query CmsLandingPageQuery($pageId: Int!, $linkedCategory: Int = -1) {
    page: strapiLandingPage(strapi_id: { eq: $pageId }) {
      url: urlPath
      title
      subtitle
      category
      service
      content
      publishedAt
      updatedAt
      layout
      CTA {
        ...CtaLink
      }
      heroImage {
        gatsbyImageDataMock
      }
      seo {
        ...SEO
      }
      sections {
        ... on STRAPI__COMPONENT_SECTION_PARAGRAPH_WITH_STICKY_IMAGE {
          ...ParagraphSectionWithStickyImage
        }
        # ... on STRAPI__COMPONENT_SECTION_YOUTUBE_VIDEOS {
        #   ...YoutubeSection
        # }
        ... on STRAPI__COMPONENT_SECTION_PARAGRAPH {
          ...ParagraphSection
        }
        ... on STRAPI__COMPONENT_SECTION_ACCORDION_SECTION {
          ...AccordionSection
        }
        ... on STRAPI__COMPONENT_SHARED_LINK {
          ...CtaLink
        }
        ... on STRAPI__COMPONENT_SECTION_TESTIMONIALS {
          ...TestimonialsSection
        }
        ... on STRAPI__COMPONENT_SECTION_CONTACT_US_MAP {
          id
          strapi_component
        }
        # ... on STRAPI__COMPONENT_SECTION_FORM {
        #   ...FormSection
        # }

        ... on STRAPI__COMPONENT_SECTION_IMAGE_SECTION {
          ...ImageSection
        }
        ... on STRAPI__COMPONENT_SECTION_NUMBERED_LIST {
          ...NumberedListSection
        }
        ... on STRAPI__COMPONENT_SECTION_SECTION_LAYOUT {
          ...SectionLayout
        }
        ... on STRAPI__COMPONENT_SECTION_COMMON_QUESTIONS {
          ...HaveQuestionsSection
        }
        ... on STRAPI__COMPONENT_SECTION_PRODUCT_GRID {
          ...ProductGridSection
        }
      }
    }
    category: strapiCategory(strapi_id: { eq: $linkedCategory }) {
      productsInStock
      urlPath
      name
      headerH1
      tag {
        brand
        model
      }
    }
    linkedCategories: allStrapiCategory(
      filter: { strapi_parent: { id: { eq: $linkedCategory } } }
    ) {
      nodes {
        productsInStock
        urlPath
        name
        headerH1
        tag {
          brand
          model
        }
      }
    }
    items: allStrapiProduct(
      sort: { fields: [in_stock, stock_date], order: [DESC, DESC] }
      limit: 12
      skip: 0
      filter: { categories: { elemMatch: { strapi_id: { eq: $linkedCategory } } } }
    ) {
      nodes {
        ...ProductCard
      }
    }
  }
`
export default CmsLandingPage
